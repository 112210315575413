<template>
  <v-card
    elevation="2"
    rounded="lg"
    flat
  >
    <v-overlay v-if="id === item.id" :value="isProcessing" absolute="true">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-toolbar
      class="rounded-b-0"
      color="primary"
      dense
      dark
      flat
    >
      <v-toolbar-title class="tw-text-sm">{{item.title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="doSelectQuestion(item)">
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1235_146)">
            <path d="M9.43594 1.9501L11.55 4.06416C11.6391 4.15322 11.6391 4.29853 11.55 4.3876L6.43125 9.50634L4.25625 9.74775C3.96563 9.78056 3.71953 9.53447 3.75234 9.24384L3.99375 7.06884L9.1125 1.9501C9.20156 1.86103 9.34688 1.86103 9.43594 1.9501ZM13.2328 1.41338L12.0891 0.269629C11.7328 -0.0866211 11.1539 -0.0866211 10.7953 0.269629L9.96563 1.09932C9.87656 1.18838 9.87656 1.33369 9.96563 1.42275L12.0797 3.53681C12.1688 3.62588 12.3141 3.62588 12.4031 3.53681L13.2328 2.70713C13.5891 2.34853 13.5891 1.76963 13.2328 1.41338ZM9 8.11416V10.5001H1.5V3.0001H6.88594C6.96094 3.0001 7.03125 2.96963 7.08516 2.91807L8.02266 1.98057C8.20078 1.80244 8.07422 1.5001 7.82344 1.5001H1.125C0.503906 1.5001 0 2.004 0 2.6251V10.8751C0 11.4962 0.503906 12.0001 1.125 12.0001H9.375C9.99609 12.0001 10.5 11.4962 10.5 10.8751V7.17666C10.5 6.92588 10.1977 6.80166 10.0195 6.97744L9.08203 7.91494C9.03047 7.96884 9 8.03916 9 8.11416Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_1235_146">
            <rect width="13.5" height="12" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </v-btn>
    </v-toolbar>
    <v-card-text class="tw-p-0">
      <v-container class="tw-px-4">
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              v-model="selected"
              :items="options"
              label="Type"
              background-color="#EFF0F6"
              dense
              filled
              solo
              hide-details
              :readonly="id !== item.id"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              :value="item.title"
              background-color="#EFF0F6"
              solo
              name="input-7-4"
              label="Question"
              placeholder="Question"
              filled
              hide-details
              rows="4"
              :readonly="id !== item.id"
              @input="(x) => doUpdateData(x, 'title')"
            />
          </v-col>
        </v-row>
        <!-- show if type === 'multiple' else hide -->
        <v-row v-if="item.type === 'multiple'">
          <v-card-text class="tw-text-left tw-py-0 tw-text-sm tw-font-semibold">
            Answers
          </v-card-text>
          <v-col
            v-for="(answer, index) in item.choices"
            :key="index"
            cols="12"
          >
            <v-textarea
              background-color="#EFF0F6"
              solo
              :value="answer.text"
              :name="`${answer.id}`"
              :id="`${answer.id}`"
              :label="`Choice ${index+1}`"
              placeholder="Answer"
              filled
              hide-details
              rows="3"
              :readonly="id !== item.id"
              @input="(x) => doUpdateData(x, 'choices', index)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="tw-p-0 tw-flex tw-flex-col">
      <div
        v-if="item.type === 'multiple'" 
        class="tw-px-4 tw-py-2 tw-w-full"
      >
        <v-btn
          class="tw-w-full"
          outlined
          color="primary"
          :disabled="id !== item.id"
          @click="doAddChoices(item)"
        >
          Add Choices
        </v-btn>
      </div>
      <div
        v-if="id === item.id"
        class="tw-px-4 tw-py-2 tw-w-full tw-flex tw-justify-between"
        style="background: #EFF0F6"
      >
        <v-btn
          text
          color="error"
          @click="doDelete(item)"
        >
          Delete
        </v-btn>
        <v-btn
          text
          color="success"
          @click="doSave(item)"
        >
          Save
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'QuestionList',
  props: {
    id: {
      type: String,
      Default: '',
    },
    item: {
      type: Object,
      Default: {},
    },
    index: {
      type: Number,
      Default: 0,
    },
    doEdit: {
      type: Function,
      Default: () => {},
    },
    doSelectQuestion: {
      type: Function,
      Default: () => {},
    },
    doSave: {
      type: Function,
      Default: () => {},
    },
    doDelete: {
      type: Function,
      Default: () => {},
    },
    doAddChoices: {
      type: Function,
      Default: () => {},
    },
    doUpdateData: {
      type: Function,
      Default: () => {},
    },
    options: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
      Default: false,
    }
  },
  data() {
    return {
      type: 'multiple',
      choices: [],
    }
  },
  computed : {
    selected: {
      get() {
        return this.item.type;
      },
      set(v) {
        this.type = v;
        this.doUpdateData(v, 'type');
        return v;
      }
    }
  },
  mounted() {
  },
  methods: {
    // component methods here
  }
}
</script>

<style lang="scss" scoped>

.theme--light {
  .v-list {
    text-align: left !important;
    }
  }
</style>